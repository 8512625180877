<template>
  <div class="main">
    <div class="row mx-4 my-4">
      <EmployeeCard></EmployeeCard>
      <div class="col-xl-9 col-lg-8 ps-4 userSettings">
        <ProfileProgress></ProfileProgress>
        <div class="mt-4 settingsBody rounded">
          <div class="pt-3 pb-0 ps-4">
            <strong>En Caso de Emergencia</strong>
          </div>
          <hr />
          <div>
            <form class="settingForm" @submit.prevent="sendBasic">
              <div class="mb-3">
                <label for="emergencyName" class="form-label"
                  >Nombre del Contacto en Caso de Emergencia</label
                >
                <input
                  type="text"
                  class="form-control"
                  name="emergencyName"
                  minlength="1"
                  :maxlength="maxEmergencyName"
                  v-model="emergencyName"
                  required
                />
              </div>
              <div class="mb-3">
                <label for="emergencyPhone" class="form-label"
                  >Teléfono del Contacto en Caso de Emergencia</label
                >
                <div class="input-group">
                  <input
                    type="number"
                    class="form-control"
                    name="emergencyPhone"
                    :min="emergencyPhoneMin"
                    :max="emergencyPhoneMax"
                    v-model="emergencyPhone"
                    required
                  />
                </div>
              </div>
              <button type="submit mb-3" class="btn btn-primary shadow-primary">
                Actualizar
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
    <Legal></Legal>
  </div>
</template>

<style lang="css" scoped>
.settingsBody {
  background-color: var(--secondary-bg);
}
.settingForm {
  margin: auto;
  padding-bottom: 20px;
  width: 50%;
  min-width: 280px;
}
@media only screen and (max-width: 990px) {
  .userSettings {
    padding: 0 !important;
  }
  .userSettings {
    margin-block: 30px;
  }
}
</style>

<script>
import Legal from "@/components/layout/Legal";
import EmployeeCard from "@/views/employee/EmployeeCard";
import ProfileProgress from "@/views/employee/ProfileProgress";

import Storage from "@/modules/storage";
import Swal from "sweetalert2";

export default {
  components: { Legal, EmployeeCard, ProfileProgress },
  name: "Employee Profile Edit Emergency",
  data() {
    return {
      maxEmergencyName: 1,
      emergencyName: "",
      emergencyPhone: "",
      emergencyPhoneMin: 0,
      emergencyPhoneMax: 1
    };
  },
  beforeCreate() {
    const store = new Storage();

    store.getData("employee", this.$route.params._id).then(employeeData => {
      store.getData("branch", employeeData.branch).then(branchData => {
        store.getData("city", branchData.city).then(cityData => {
          localStorage.setItem(
            "currentLocation",
            "Empleados / Perfiles / " +
              cityData.name +
              " / " +
              employeeData.name +
              " / En Caso de Emergencia"
          );
        });
      });
    });
  },
  mounted() {
    const store = new Storage();
    store.getData("employee", this.$route.params._id).then(employee => {
      this.emergencyName = employee.emergencyName;
      this.emergencyPhone = employee.emergencyPhone;
    });
    store.getData("schema").then(schemaList => {
      schemaList.forEach(schema => {
        if (schema.store === "employee") {
          this.maxEmergencyName = schema.data.emergencyName.max;
          this.emergencyPhoneMin = schema.data.emergencyPhone.min;
          this.emergencyPhoneMax = schema.data.emergencyPhone.max;
        }
      });
    });
  },
  methods: {
    sendBasic() {
      Swal.fire({
        icon: "warning",
        title: "Actualizando información",
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        stopKeydownPropagation: true,
        timerProgressBar: true,
        didOpen: () => {
          Swal.showLoading();
          setInterval(() => {
            const content = Swal.getContent();
            if (content) {
              const b = content.querySelector("b");
              if (b) {
                b.textContent = Swal.getTimerLeft();
              }
            }
          }, 100);
        }
      });
      const store = new Storage();
      const data = {
        _id: this.$route.params._id,
        emergencyName: this.emergencyName,
        emergencyPhone: this.emergencyPhone
      };
      store
        .updateData("employee", [data], true, "optima")
        .then(() => {
          Swal.fire({
            icon: "success",
            title: "Datos Actualizados",
            text: "Todos los datos han sido sincronizados"
          }).then(() => {
            this.$router.go();
          });
        })
        .catch(error => {
          console.log(error);
          Swal.fire({
            icon: "error",
            title: "Error al actualizar los datos",
            text:
              "Los datos han sido guardados en su computador o celular, una vez se restablesca la conexión, los datos se sincronizarán con el servidor"
          }).then(result => {
            this.$router.go();
          });
        });
    }
  }
};
</script>
